import { Logo } from '@/components/Logo';
import { useAppDispatch } from '@/customHooks/storeHooks';
import { actionClearStore } from '@/store/globalAsyncFunc';
import { CustomMainButton } from '@/styles/components/Button';
import { CustomTitle } from '@/styles/components/Titles';
import { t } from 'i18next';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import styles from './styles.module.scss';
const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      <div className={styles.logo_wrapper}>
        <Logo />
      </div>
      <div className={styles.content_container}>
        <CustomTitle marginBottom={20}>{t('errors:error_boundary.title')}</CustomTitle>
        <span className={styles.error_message}>{t('errors:error_boundary.message')}</span>
        <CustomMainButton
          onClick={async () => {
            await dispatch(actionClearStore());
            setTimeout(() => {
              window.location.reload();
            }, 200);
            resetErrorBoundary();
          }}
        >
          {t('errors:error_boundary.action')}
        </CustomMainButton>
      </div>
    </div>
  );
};

export default ErrorFallback;
