import { Roles } from '../enum/userRoles';

export function isUserRole(roles: string[]) {
  const availableRoles: string[] = Object.values(Roles);
  if (!roles || !Array.isArray(roles)) {
    return false;
  }
  return roles.every(role => availableRoles.includes(role));
}

export function isSuperAdmin(roles: Roles[]) {
  if (!roles || !Array.isArray(roles)) {
    return false;
  }
  if (roles.includes(Roles.SUPERADMIN)) {
    return true;
  }
  return false;
}

export function isAdmin(roles: Roles[]) {
  if (!roles || !Array.isArray(roles)) {
    return false;
  }
  if (roles.includes(Roles.ADMIN)) {
    return true;
  }
  return false;
}
