import { decodeToken } from '@/utils/common';
import { actionClearStore } from '@store/globalAsyncFunc';
import { store } from '@store/index';
import { actionSetToken } from '@store/slices/authSlice/authSlice';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { apiRefresh } from './auth/api';

export const axiosInstance = axios.create({ baseURL: process.env.API_URL || '/' });

export const AxiosProvider: React.FC = () => {
  axiosInstance.interceptors.request.use(config => {
    config.headers = config.headers ?? {};
    config.headers['access-token'] = store.getState().auth.access_token || '';
    return config;
  });

  let isRefreshing = false;
  let requestQueue: ((data: string) => void)[] = [];

  function addToQueue(callback: (data: string) => void): void {
    requestQueue.push(callback);
  }
  function executeQueue(token: string): void {
    requestQueue.forEach(callback => callback(token));
  }

  axiosInstance.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      if (error?.config?.url === '/api/v1/auth/refresh') {
        store.dispatch(actionClearStore());
        return;
      }

      if (error.config && error.response?.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;

          apiRefresh(store.getState().auth.refresh_token || '')
            .then(response => {
              const decode = decodeToken(response);
              store.dispatch(actionSetToken(decode));
              executeQueue(response.access_token);
              isRefreshing = false;
              requestQueue = [];
            })
            .catch(() => {
              isRefreshing = false;
              requestQueue = [];
            });
        }

        return new Promise(resolve =>
          addToQueue((token: string) => {
            error.config.headers = error.config.headers ?? {};
            error.config.headers['access-token'] = token;
            resolve(axiosInstance(error.config));
          }),
        );
      }

      return Promise.reject(error);
    },
  );

  return null;
};
