import { apiGetSingleDevice } from '@/api/singleDevice/api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Device, SingleDeviceInitialState } from './types';

const initialState: SingleDeviceInitialState = {
  isLoading: false,
  device: null,
};

export const actionGetSingleDevice = createAsyncThunk<Device, string>(
  'single_device/getDevice',
  async (imei, thunkAPI) => {
    try {
      return apiGetSingleDevice(imei);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const singleDeviceSlice = createSlice({
  name: 'singleDeviceSlice',
  initialState,
  reducers: {
    actionClearSingleDeviceStore: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionGetSingleDevice.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      actionGetSingleDevice.fulfilled,
      (state, { payload }: PayloadAction<Device>) => {
        state.device = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(actionGetSingleDevice.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { actionClearSingleDeviceStore } = singleDeviceSlice.actions;

export default singleDeviceSlice.reducer;
