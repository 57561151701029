import { useAppDispatch, useAppSelector } from '@/customHooks/storeHooks';
import { actionToggleDrawer } from '@/store/slices/themeSlice/themeSlice';
import { CustomDrawer, DrawerHeader } from '@/styles/components/CustomDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, List } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HiUsers } from 'react-icons/hi';
import { IoCarSport } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import MenuItem from './menu-items/MenuItem';
import styles from './styles.module.scss';

const Drawer: React.FC = () => {
  const navigate = useNavigate();
  const isDrawerOpen = useAppSelector(state => state.theme.isDrawerOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigateTo = useCallback((path: string) => {
    navigate(path);
  }, []);

  const handleToggleDrawer = () => {
    dispatch(actionToggleDrawer());
  };

  return (
    <CustomDrawer variant="permanent" open={isDrawerOpen}>
      <DrawerHeader>
        <IconButton onClick={handleToggleDrawer}>
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <div className={styles.drawer_body}>
        <MenuItem
          Icon={IoCarSport}
          path={'/devices'}
          title={t('navigation:devices')}
          navigate={navigateTo}
        />
        <MenuItem
          Icon={HiUsers}
          path={'/users'}
          title={t('navigation:users')}
          navigate={navigateTo}
        />
      </div>
    </CustomDrawer>
  );
};

export default Drawer;
