import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationInitialState, Variant } from './types';

const initialState: NotificationInitialState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    actionCreateSnackbar: (
      state,
      action: PayloadAction<{ message: string | string[]; variant: Variant }>,
    ) => {
      const options = {
        key: new Date().getTime() + Math.random(),
        variant: action.payload.variant,
      };

      const message = Array.isArray(action.payload.message)
        ? action.payload.message.join(', ')
        : action.payload.message;

      state.notifications.push({ message, options: options });
    },
    actionRemoveSnackbar: (state, action: PayloadAction<string | number>) => {
      state.notifications = state.notifications.filter(
        notification => notification.options.key !== action.payload,
      );
    },
    actionClearNotificationStore: state => {
      return initialState;
    },
  },
});

export const { actionCreateSnackbar, actionRemoveSnackbar, actionClearNotificationStore } =
  notificationSlice.actions;

export default notificationSlice.reducer;
