import { store } from '@/store';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = store.getState().theme.drawerWidth;

export const CustomHeader = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: '#fff',
  border: 'none',
  borderBottom: '1px solid #E0E0E0',
  boxShadow: 'none',
  height: '45px',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  display: 'flex',
  // flex: 1,
  alignSelf: 'flex-start',
  justifyContent: 'center',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '@media(min-width: 1024px)': {
    height: '60px',
  },
  '@media(min-width: 1980px)': {
    height: '80px',
  },
}));

export const CustomToolbar = styled(Toolbar)<ToolbarProps>(() => ({
  height: 'inherit',
  minHeight: '45px',
  padding: '0px !important',
  '@media(min-width: 600px)': {
    height: 'inherit',
    minHeight: '45px',
  },
  '@media(min-width: 1024px)': {
    height: '60px',
  },
  '@media(min-width: 1980px)': {
    height: '80px',
  },
}));
