const size = {
  mobile: '375px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
};

export const device = {
  mobileM: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
};
