import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionClearAuthStore } from './slices/authSlice/authSlice';
import { actionClearDevicesStore } from './slices/deviceSlice/deviceSlice';
import { actionClearNotificationStore } from './slices/notificationSlice/notificationSlice';
import { actionClearSingleDeviceStore } from './slices/singleDeviceSlice/singleDeviceSlice';
import { actionClearSingleUserStore } from './slices/singleUserSlice/singleUserSlice';
import { actionClearSuperAdminStore } from './slices/superAdminSlice/superAdminSlice';
import { actionClearUsersAdministrationStore } from './slices/usersAdministrationSlice/usersAdministrationSlice';

export const actionClearStore = createAsyncThunk<void>(
  'global, clearStore',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(actionClearNotificationStore());
      thunkAPI.dispatch(actionClearAuthStore());
      thunkAPI.dispatch(actionClearSuperAdminStore());
      thunkAPI.dispatch(actionClearUsersAdministrationStore());
      thunkAPI.dispatch(actionClearDevicesStore());
      thunkAPI.dispatch(actionClearSingleDeviceStore());
      thunkAPI.dispatch(actionClearSingleUserStore());
    } catch (error) {
      // do nothing
    }
  },
);
