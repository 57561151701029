import { apiLogin, apiLogout } from '@/api/auth/api';
import { actionClearStore } from '@/store/globalAsyncFunc';
import { decodeToken } from '@/utils/common';
import { isAxiosError } from '@/utils/type-guards/isAxiosError';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionCreateSnackbar } from '../notificationSlice/notificationSlice';
import { AuthorizationInitialState, LoginResponseDecode, loginRequestType } from './types';

const initialState: AuthorizationInitialState = {
  access_token: null,
  refresh_token: null,
  invite_token: null,
  user_id: null,
  user_roles: null,
  passwordRestored: false,
  isLoading: false,
};

export const actionLogin = createAsyncThunk<LoginResponseDecode, loginRequestType>(
  'auth/Login',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await apiLogin({ email: email, password: password });
      const decode = decodeToken(response);
      return decode;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response.status === 400) {
          thunkAPI.dispatch(
            actionCreateSnackbar({ message: error.response?.data?.message, variant: 'error' }),
          );
          return thunkAPI.rejectWithValue(error);
        }
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  },
);

export const actionLogOut = createAsyncThunk<void>('auth/LogOut', async (_, thunkAPI) => {
  try {
    const response = await apiLogout();
    thunkAPI.dispatch(actionClearStore());
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    actionSetToken: (state, { payload }: PayloadAction<LoginResponseDecode>) => {
      state.access_token = payload.response.access_token;
      state.refresh_token = payload.response.refresh_token;
      state.user_id = payload.decode.id;
      state.user_roles = payload.decode.roles;
    },
    actionClearAuthStore: () => {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder.addCase(actionLogin.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionLogin.fulfilled, (state, action: PayloadAction<LoginResponseDecode>) => {
      state.access_token = action.payload.response.access_token;
      state.refresh_token = action.payload.response.refresh_token;
      state.user_id = action.payload.decode.id;
      state.user_roles = action.payload.decode.roles;
      state.isLoading = false;
    });
    builder.addCase(actionLogin.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { actionSetToken, actionClearAuthStore } = authSlice.actions;

export default authSlice.reducer;
