import { apiGetSingleUser } from '@/api/users/api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleUserInitialState, User } from './types';

export const actionGetSingleUser = createAsyncThunk<User, string>(
  'single_user/getUser',
  async (id, thunkAPI) => {
    try {
      return await apiGetSingleUser(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const initialState: SingleUserInitialState = {
  isLoading: false,
  user: null,
};

const singleUserSlice = createSlice({
  name: 'singleUser',
  initialState,
  reducers: {
    actionClearSingleUserStore: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionGetSingleUser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetSingleUser.fulfilled, (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
      state.isLoading = false;
    });
    builder.addCase(actionGetSingleUser.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { actionClearSingleUserStore } = singleUserSlice.actions;

export default singleUserSlice.reducer;
