import { axiosInstance } from '../axios.provider';
import { LoginDto } from './dto/login.dto';
import { LoginResponse, RefreshResponse } from './response/login.response';

export async function apiLogin(payload: LoginDto): Promise<LoginResponse> {
  const response = await axiosInstance.post('/api/v1/auth/login/dashboard', payload);
  return response.data;
}

export async function apiLogout(): Promise<void> {
  const response = await axiosInstance.post('/api/v1/auth/logout');
  return response.data;
}

export async function apiRefresh(token: string): Promise<RefreshResponse> {
  const response = await axiosInstance.get<RefreshResponse>('/api/v1/auth/refresh', {
    params: {},
    headers: {
      'refresh-token': token,
    },
  });

  return response.data;
}

export async function apiForgotPassword(email: string): Promise<void> {
  const response = await axiosInstance.post('/api/v1/auth/password/forgot', { email });
  return response.data;
}

export async function apiRestorePassword({
  new_password,
  token,
}: {
  new_password: string;
  token: string;
}): Promise<void> {
  const response = await axiosInstance.post(
    '/api/v1/auth/password/restore',
    { new_password },
    {
      headers: {
        'password-recovery-token': token,
      },
    },
  );
  return response.data;
}
