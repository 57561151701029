import { OperatorType } from '@/api/common/search.dto';
import { apiGetDeviceList } from '@/api/devices/api';
import { DeviceListResponse } from '@/api/devices/response/device-list.response';
import { RootState } from '@/store';
import { toggleDirection } from '@/utils/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionCreateSnackbar } from '../notificationSlice/notificationSlice';
import { DeviceInitialState, FilterColumns, SortColumns } from './types';
import { FilterType } from '@/utils/types/Filters';

const initialState: DeviceInitialState = {
  data: {
    count: 0,
    list: [],
  },
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'imei',
    filterType: 'text',
    displayFilterName: 'Tracker id',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  isLoading: false,
};

export const actionGetDeviceList = createAsyncThunk<DeviceListResponse, void, { state: RootState }>(
  'devices/get-list',
  async (_, thunkAPI) => {
    const { page, limit, search, column, operator, sort, direction } =
      thunkAPI.getState().devices.query;
    const offset = (page - 1) * limit;
    try {
      return await apiGetDeviceList({ offset, limit, search, column, operator, sort, direction });
    } catch (error) {
      thunkAPI.dispatch(
        actionCreateSnackbar({ message: error.response?.data?.message, variant: 'error' }),
      );
      thunkAPI.rejectWithValue(error);
    }
  },
);

export const actionDevicesSetPage = createAsyncThunk<void, number, { state: RootState }>(
  'devices/set-page',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetDeviceList());
  },
);

export const actionDevicesSetLimit = createAsyncThunk<void, number, { state: RootState }>(
  'devices/set-limit',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetDeviceList());
  },
);

export const actionDevicesSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('devices/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetDeviceList());
});

export const actionDevicesSetColumn = createAsyncThunk<
  void,
  { data: string; type: FilterType; displayFilterName: string },
  { state: RootState }
>('devices/set-column', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetDeviceList());
  // if (thunkAPI.getState().devices.query.search) {
  // }
});

export const actionDevicesSetOperator = createAsyncThunk<void, string, { state: RootState }>(
  'devices/set-operator',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().devices.query.search) {
      thunkAPI.dispatch(actionGetDeviceList());
    }
  },
);

export const actionDevicesSetSort = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('devices/set-sort', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetDeviceList());
});

export const devicesSlice = createSlice({
  name: 'devicesSlice',
  initialState,
  reducers: {
    actionClearDevicesStore: state => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionGetDeviceList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetDeviceList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.rows;
      state.isLoading = false;
    });
    builder.addCase(actionGetDeviceList.rejected, state => {
      state.isLoading = false;
    });
    /**
     * Query
     */
    builder.addCase(actionDevicesSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionDevicesSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionDevicesSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionDevicesSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg.data as FilterColumns;
      state.query.filterType = action.meta.arg.type as FilterType;
      state.query.search = undefined;
      state.query.displayFilterName = action.meta.arg.displayFilterName as string;
      if (state.query.search) {
        state.query.page = 1;
      }
      if (action.meta.arg.type === 'text') {
        state.query.operator = 'co';
      }
      if (action.meta.arg.type === 'boolean') {
        state.query.operator = 'eq';
      }
      if (action.meta.arg.type === 'date') {
        state.query.operator = 'co';
      }
    });
    builder.addCase(actionDevicesSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as OperatorType;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionDevicesSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction ? (action.meta.arg as SortColumns) : undefined;
    });
  },
});

export const { actionClearDevicesStore } = devicesSlice.actions;

export default devicesSlice.reducer;
