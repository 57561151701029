import styled from 'styled-components';
import { device } from './sizes';

interface TextProps {
  color?: string;
}

export const LoginTitle = styled.h1<TextProps>`
  font-size: 16px;
  color: #101010;
  font-weight: 700;

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.laptop} {
    font-size: 24px;
  }
`;

export const CustomLink = styled.h3<TextProps>`
  fontFamily: 'Open Sans',
  font-style: normal;
  font-weight: 400;
  color: #101010;
  font-size: 16px;
  line-height: 19px;

  :hover {
    cursor: pointer;
  }
`;

export const TableTitle = styled.h2<TextProps>`
  color: '#101010';
  font-size: 16px;
  font-wight: 500;
  fontfamily: 'Open Sans';
  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.laptop} {
    font-size: 24px;
  }
`;

export const CustomTitle = styled.h2<TextProps>`
  color: ${({ color }) => (color ? color : '#101010')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '24px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : 500)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
`;
