import { OperatorType } from '@/api/common/search.dto';
import { apiChangeUserStatus, apiGetAllUsersList } from '@/api/users/api';
import { UserStatus } from '@/api/users/dto/singleUser.dto';
import { UsersListResponse } from '@/api/users/response/users-list-response';
import { RootState } from '@/store';
import { toggleDirection } from '@/utils/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionCreateSnackbar } from '../notificationSlice/notificationSlice';
import { FilterColumns, SortColumns, UsersAdministrationInitialState } from './types';
import { t } from 'i18next';
import { ObjectIdResponse } from '@/api/common/idResponse';
import { FilterType } from '@/utils/types/Filters';

const initialState: UsersAdministrationInitialState = {
  data: {
    count: 0,
    list: [],
  },
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'email',
    filterType: 'text',
    displayFilterName: 'Email',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  isLoading: false,
};

export const actionGetAllUsersList = createAsyncThunk<
  UsersListResponse,
  void,
  { state: RootState }
>('usersAdministration/get-list', async (_, thunkAPI) => {
  const { page, limit, search, column, operator, sort, direction } =
    thunkAPI.getState().usersAdministration.query;
  const offset = (page - 1) * limit;
  try {
    return await apiGetAllUsersList({ offset, limit, search, column, operator, sort, direction });
  } catch (error) {
    thunkAPI.dispatch(
      actionCreateSnackbar({ message: error.response?.data?.message, variant: 'error' }),
    );
    thunkAPI.rejectWithValue(error);
  }
});

export const actionChangeUserStatus = createAsyncThunk<ObjectIdResponse, UserStatus>(
  'usersAdministration/change-user-status',
  async ({ id, status }, thunkAPI) => {
    try {
      const response = await apiChangeUserStatus({ id, status });
      await thunkAPI.dispatch(actionGetAllUsersList());
      thunkAPI.dispatch(
        actionCreateSnackbar({
          message: t('modals:change_user_status.success'),
          variant: 'success',
        }),
      );
      return response;
    } catch (error) {
      thunkAPI.dispatch(
        actionCreateSnackbar({ message: error.response.data.message, variant: 'error' }),
      );
      thunkAPI.rejectWithValue(error);
    }
  },
);

export const actionUsersSetPage = createAsyncThunk<void, number, { state: RootState }>(
  'usersAdministration/set-page',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAllUsersList());
  },
);

export const actionUsersSetLimit = createAsyncThunk<void, number, { state: RootState }>(
  'usersAdministration/set-limit',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAllUsersList());
  },
);

export const actionUsersSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('usersAdministration/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetAllUsersList());
});

export const actionUsersSetColumn = createAsyncThunk<
  void,
  { data: string; type: FilterType; displayFilterName: string },
  { state: RootState }
>('usersAdministration/set-column', async (_, thunkAPI) => {
  if (thunkAPI.getState().usersAdministration.query.search) {
    thunkAPI.dispatch(actionGetAllUsersList());
  }
});

export const actionUsersSetOperator = createAsyncThunk<void, string, { state: RootState }>(
  'usersAdministration/set-operator',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().usersAdministration.query.search) {
      thunkAPI.dispatch(actionGetAllUsersList());
    }
  },
);

export const actionUsersSetSort = createAsyncThunk<void, string | undefined, { state: RootState }>(
  'usersAdministration/set-sort',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAllUsersList());
  },
);

export const usersAdministrationSlice = createSlice({
  name: 'usersAdministrationSlice',
  initialState,
  reducers: {
    actionClearUsersAdministrationStore: state => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionGetAllUsersList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetAllUsersList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.rows;
      state.isLoading = false;
    });
    builder.addCase(actionGetAllUsersList.rejected, state => {
      state.isLoading = false;
    });
    /**
     * Query
     */
    builder.addCase(actionUsersSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionUsersSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionUsersSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionUsersSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg.data as FilterColumns;
      state.query.filterType = action.meta.arg.type as FilterType;
      state.query.displayFilterName = action.meta.arg.displayFilterName as string;
      if (state.query.search) {
        state.query.page = 1;
      }
      if (action.meta.arg.type === 'text') {
        state.query.operator = 'co';
      }
      if (action.meta.arg.type === 'boolean') {
        state.query.operator = 'eq';
      }
      if (action.meta.arg.type === 'date') {
        state.query.operator = 'co';
      }
    });
    builder.addCase(actionUsersSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as OperatorType;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionUsersSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction ? (action.meta.arg as SortColumns) : undefined;
    });
  },
});

export const { actionClearUsersAdministrationStore } = usersAdministrationSlice.actions;

export default usersAdministrationSlice.reducer;
