import { useAppSelector } from '@/customHooks/storeHooks';
import { isAdmin, isSuperAdmin } from '@/utils/type-guards/isUserRole';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PublicRoute: React.FC = () => {
  const userRoles = useAppSelector(state => state.auth.user_roles);
  const isAuthorized = useAppSelector(
    state =>
      !!state.auth.access_token &&
      !!state.auth.refresh_token &&
      !!state.auth.user_id &&
      !!state.auth.user_roles,
  );

  const pathRole = () => {
    if (isSuperAdmin(userRoles)) {
      return '/admins';
    }
    if (isAdmin(userRoles)) {
      return '/devices';
    }
  };

  const path = pathRole();

  return !isAuthorized ? <Outlet /> : <Navigate to={path} />;
};

export default PublicRoute;
