import App from '@app/App';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AxiosProvider } from './api/axios.provider';
import './i18n/i18n';
import ErrorFallback from './scenes/ErrorBoundary/ErrorFallback';
import { persistor, store } from './store';
import './styles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AxiosProvider />
      <SnackbarProvider autoHideDuration={3000} maxSnack={5}>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </SnackbarProvider>
    </PersistGate>
  </Provider>,
);
