import { useAppSelector } from '@/customHooks/storeHooks';
import { Roles } from '@/utils/enum/userRoles';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface PrivateRouteProps {
  roles: Roles[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles }) => {
  const userRoles = useAppSelector(state => state.auth.user_roles);
  const isAuthorized = useAppSelector(
    state =>
      !!state.auth.access_token &&
      !!state.auth.refresh_token &&
      !!state.auth.user_id &&
      !!state.auth.user_roles,
  );

  const isAllowed = roles.some(role => userRoles?.includes(role));

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }
  // if (isAuthorized && !isAllowed) {
  //   return <AccessDenid />;
  // }

  return <Outlet />;
};

export default PrivateRoute;
