import { apiLogout } from '@/api/auth/api';
import { useAppDispatch } from '@/customHooks/storeHooks';
import { RootState } from '@/store';
import { actionClearStore } from '@/store/globalAsyncFunc';
import { actionChangeLanguage } from '@/store/slices/themeSlice/themeSlice';
import { CustomMainButtonOutline } from '@/styles/components/Button';
import { CustomHeader, CustomToolbar } from '@/styles/components/CustomHeader';
import micareLogo from '@images/micare-logo.png';
import { FormControl, FormControlLabel, IconButton, Menu, Radio, RadioGroup } from '@mui/material';
import i18next, { changeLanguage } from 'i18next';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoLogOutOutline } from 'react-icons/io5';
import { MdLanguage } from 'react-icons/md';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const language = useSelector((state: RootState) => state.theme.currentLanguage);
  const handleChangeLang = event => {
    dispatch(actionChangeLanguage(event.target.value));
    changeLanguage(event.target.value).then(() => {
      i18next.options.lng = event.target.value;
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = () => {
    apiLogout()
      .then(() => {
        dispatch(actionClearStore());
      })
      .catch(error => {
        enqueueSnackbar(error);
      })
      .finally(() => {
        dispatch(actionClearStore());
      });
  };

  return (
    <CustomHeader position="static">
      <CustomToolbar>
        <div className={styles.tools_container}>
          <div className={styles.left_tools}>
            <div className={styles.logo_wrapper}>
              <img className={styles.image} src={micareLogo} />
            </div>
          </div>
          <div className={styles.right_tools}>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MdLanguage />
            </IconButton>
            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
              <FormControl>
                <div className={styles.menu_container}>
                  <RadioGroup
                    value={language}
                    name="controlled-radio-buttons-group"
                    defaultValue={language}
                    onChange={handleChangeLang}
                  >
                    <FormControlLabel value="en" control={<Radio />} label="English" />
                    <FormControlLabel value="de" control={<Radio />} label="Deutsch" />
                  </RadioGroup>
                </div>
              </FormControl>
            </Menu>
            <CustomMainButtonOutline
              variant="text"
              startIcon={<IoLogOutOutline color="#102334" />}
              onClick={handleLogout}
            >
              {t('action:logout')}
            </CustomMainButtonOutline>
          </div>
        </div>
      </CustomToolbar>
    </CustomHeader>
  );
};

export default Header;
