import Drawer from '@/components/drawer/Drawer';
import Header from '@/components/header/Header';
import Notifier from '@/components/Notifier';
import { useAppSelector } from '@/customHooks/storeHooks';
import Router from '@/routes/Routes';
import { RootState } from '@/store';
import { Roles } from '@/utils/enum/userRoles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Scene from './Scene';
import styles from './styles.module.scss';

const App = () => {
  const userRoles = useAppSelector(state => state.auth.user_roles);
  const isAuthorized = useSelector(
    (state: RootState) =>
      !!state.auth.access_token &&
      !!state.auth.refresh_token &&
      !!state.auth.user_id &&
      !!state.auth.user_roles,
  );

  const location = useLocation();
  const currentPage = location.pathname;
  const routesWithoutNavigation = ['/email', '/password'];
  const isDrawerShow = userRoles?.some(item => item === Roles.SUPERADMIN);

  const isNavigationVisible =
    !isDrawerShow &&
    isAuthorized &&
    routesWithoutNavigation.every(route => !currentPage.startsWith(route));

  return (
    <div className={styles.app_container}>
      <div className={styles.scene_wrapper}>
        {isNavigationVisible && <Drawer />}
        <Scene>
          {isAuthorized && <Header />}
          <Router />
        </Scene>
      </div>
      <Notifier />
    </div>
  );
};

export default App;
