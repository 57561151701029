import { axiosInstance } from '../axios.provider';
import { DevicesQueryParams } from './dto/devices.dto';
import { DeviceListResponse } from './response/device-list.response';

export async function apiGetDeviceList(params: DevicesQueryParams): Promise<DeviceListResponse> {
  const response = await axiosInstance.get('/api/v1/devices', { params });
  return response.data;
}

export async function apiUpdateDevice({
  imei,
  description,
}: {
  imei: string;
  description: string;
}): Promise<void> {
  const response = await axiosInstance.put(`/api/v1/devices/${imei}`, { description });
  return response.data;
}
