import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import authSlice from './slices/authSlice/authSlice';
import { AuthorizationInitialState } from './slices/authSlice/types';
import deviceSlice from './slices/deviceSlice/deviceSlice';
import { DeviceInitialState } from './slices/deviceSlice/types';
import notificationSlice from './slices/notificationSlice/notificationSlice';
import { NotificationInitialState } from './slices/notificationSlice/types';
import singleDeviceSlice from './slices/singleDeviceSlice/singleDeviceSlice';
import { SingleDeviceInitialState } from './slices/singleDeviceSlice/types';
import singleUserSlice from './slices/singleUserSlice/singleUserSlice';
import { SingleUserInitialState } from './slices/singleUserSlice/types';
import superAdminSlice from './slices/superAdminSlice/superAdminSlice';
import { SuperAdminInitialState } from './slices/superAdminSlice/types';
import themeSlice from './slices/themeSlice/themeSlice';
import { InitialThemeSlice } from './slices/themeSlice/types';
import { UsersAdministrationInitialState } from './slices/usersAdministrationSlice/types';
import usersAdministrationSlice from './slices/usersAdministrationSlice/usersAdministrationSlice';

const authConfig = {
  key: 'auth',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const themeConfig = {
  key: 'theme',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const notificationConfig = {
  key: 'notification',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const superAdminConfig = {
  key: 'superAdmin',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const usersAdministrationConfig = {
  key: 'usersAdministration',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const devicesConfig = {
  key: 'devices',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const singleDeviceConfig = {
  key: 'singleDevice',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const singleUserConfig = {
  key: 'singleUser',
  version: -1,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: false,
  migrate: createMigrate({}, { debug: false }),
};

const rootReducer = combineReducers({
  theme: persistReducer<InitialThemeSlice>(themeConfig, themeSlice),
  auth: persistReducer<AuthorizationInitialState>(authConfig, authSlice),
  notification: persistReducer<NotificationInitialState>(notificationConfig, notificationSlice),
  superAdmin: persistReducer<SuperAdminInitialState>(superAdminConfig, superAdminSlice),
  usersAdministration: persistReducer<UsersAdministrationInitialState>(
    usersAdministrationConfig,
    usersAdministrationSlice,
  ),
  devices: persistReducer<DeviceInitialState>(devicesConfig, deviceSlice),
  singleDevice: persistReducer<SingleDeviceInitialState>(singleDeviceConfig, singleDeviceSlice),
  singleUser: persistReducer<SingleUserInitialState>(singleUserConfig, singleUserSlice),
  // theme: themeSlice,
  // auth: authSlice,
  // notification: notificationSlice,
  // superAdmin: superAdminSlice,
  // usersAdministration: usersAdministrationSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
