import React from 'react';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '@/customHooks/storeHooks';
import {
  actionClearNotificationStore,
  actionRemoveSnackbar,
} from '@/store/slices/notificationSlice/notificationSlice';

let displayed = [];

const Notifier = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(store => store.notification.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(options.key);
        return;
      }

      if (displayed.includes(options.key)) return;

      enqueueSnackbar(message, {
        ...options,
        onExited: (event, myKey) => {
          dispatch(actionRemoveSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(options.key);
    });
    return () => {
      dispatch(actionClearNotificationStore());
    };
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
