import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialThemeSlice } from './types';

const initialState: InitialThemeSlice = {
  isDrawerOpen: false,
  drawerWidth: 240,
  currentLanguage: 'en',
};

const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    actionToggleDrawer: state => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    actionChangeLanguage: (state, action: PayloadAction<'en' | 'de'>) => {
      state.currentLanguage = action.payload;
    },
  },
});

export const { actionToggleDrawer, actionChangeLanguage } = themeSlice.actions;

export default themeSlice.reducer;
