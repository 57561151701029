import useQuery from '@/customHooks/useQuery';
import { RootState } from '@/store';
import { Roles } from '@/utils/enum/userRoles';
import { CircularProgress } from '@mui/material';
import i18next, { changeLanguage } from 'i18next';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import styles from './styles.module.scss';

const LoginScreen = React.lazy(
  () => import(/* webpackChunkName: "LoginScreen" */ '@scenes/Authorization/LoginScreen'),
);

const AdminsScreen = React.lazy(
  () => import(/* webpackChunkName: "AdminsScreen" */ '@scenes/Admins/AdminsScreen'),
);

const UsersScreen = React.lazy(
  () => import(/* webpackChunkName: "UsersScreen" */ '@scenes/Users/UsersScreen'),
);

const DevicesScreen = React.lazy(
  () => import(/* webpackChunkName: "DevicesScreen" */ '@scenes/Devices/DevicesScreen'),
);

const DeviceScreen = React.lazy(
  () => import(/* webpackChunkName: "DeviceScreen" */ '@scenes/Device/DeviceScreen'),
);

const ForgotPasswordScreen = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordScreen" */ '@scenes/ForgotPassword/ForgotPasswordScreen'
    ),
);

const ConfirmEmailScreen = React.lazy(
  () =>
    import(/* webpackChunkName: "ConfirmEmailScreen" */ '@scenes/ConfirmEmail/ConfirmEmailScreen'),
);

const RestorePasswordScreen = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RestorePasswordScreen" */ '@scenes/RestorePassword/RestorePasswordScreen'
    ),
);

const UserScreen = React.lazy(
  () => import(/* webpackChunkName: "UserScreen" */ '@scenes/User/UserScreen'),
);

const Router: React.FC = () => {
  const query = useQuery();

  const language = useSelector((state: RootState) => state.theme.currentLanguage);

  useEffect(() => {
    changeLanguage(language).then(() => {
      i18next.options.lng = language;
    });
  }, []);

  return (
    <Suspense
      fallback={
        <div className={styles.fallback_container}>
          <CircularProgress
            color="primary"
            sx={{
              position: 'absolute',
              alignSelf: 'center',
            }}
          />
        </div>
      }
    >
      <Routes>
        <Route element={<PrivateRoute roles={[Roles.SUPERADMIN]} />}>
          <Route path="/admins" element={<AdminsScreen />} />
        </Route>

        <Route element={<PrivateRoute roles={[Roles.ADMIN]} />}>
          <Route path="/devices" element={<DevicesScreen />} />
          <Route path="/devices/:imei" element={<DeviceScreen />} />
          <Route path="/users" element={<UsersScreen />} />
          <Route path="/users/:id" element={<UserScreen />} />
        </Route>

        <Route element={<PublicRoute />}>
          <Route path={'/'} element={<LoginScreen />} />
          <Route path={'/login'} element={<LoginScreen />} />
          <Route path={'/password/forgot'} element={<ForgotPasswordScreen />} />
        </Route>

        <Route path="/email/confirm/" element={<ConfirmEmailScreen token={query.get('token')} />} />
        <Route
          path="/password/restore/"
          element={<RestorePasswordScreen token={query.get('token')} />}
        />
      </Routes>
    </Suspense>
  );
};

export default Router;
