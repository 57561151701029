import { axiosInstance } from '../axios.provider';
import { SingleDevice } from './response/singleDevice.response';

export async function apiGetSingleDevice(imei: string): Promise<SingleDevice> {
  const response = await axiosInstance.get(`/api/v1/devices/${imei}`, {});
  return response.data;
}

export async function apiEnableAlarmMode(imei: string, is_alarm_mode: boolean): Promise<void> {
  const response = await axiosInstance.put(`/api/v1/devices/${imei}/alarm`, { is_alarm_mode });
  return response.data;
}
