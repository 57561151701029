import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import actionDe from './locales/de/action.json';
import deviceDe from './locales/de/device.json';
import errorsDe from './locales/de/errors.json';
import formsDe from './locales/de/forms.json';
import modalsDe from './locales/de/modals.json';
import navigationDe from './locales/de/navigation.json';
import screensDe from './locales/de/screens.json';
import tablesDe from './locales/de/tables.json';
import validationDe from './locales/de/validation.json';
import actionEn from './locales/en/action.json';
import deviceEn from './locales/en/device.json';
import errorsEn from './locales/en/errors.json';
import formsEn from './locales/en/forms.json';
import modalsEn from './locales/en/modals.json';
import navigationEn from './locales/en/navigation.json';
import screensEn from './locales/en/screens.json';
import tablesEn from './locales/en/tables.json';
import validationEn from './locales/en/validation.json';

export const resources = {
  en: {
    action: actionEn,
    forms: formsEn,
    tables: tablesEn,
    modals: modalsEn,
    validation: validationEn,
    errors: errorsEn,
    navigation: navigationEn,
    screens: screensEn,
    device: deviceEn,
  },
  de: {
    action: actionDe,
    forms: formsDe,
    tables: tablesDe,
    modals: modalsDe,
    validation: validationDe,
    errors: errorsDe,
    navigation: navigationDe,
    screens: screensDe,
    device: deviceDe,
  },
} as const;

export const defaultNS: keyof (typeof resources)['en'] = 'action';
export const ns: Array<keyof (typeof resources)['en']> = [
  'action',
  'device',
  'errors',
  'forms',
  'modals',
  'navigation',
  'screens',
  'tables',
  'validation',
];

export const i18n = i18next;
i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',

  nsSeparator: ':',
  keySeparator: '.',
  pluralSeparator: '_',
  compatibilityJSON: 'v3',

  defaultNS,
  ns,
  resources,

  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
});
