import { RepeatOneSharp } from '@mui/icons-material';
import { ObjectIdResponse } from '../common/idResponse';
import { axiosInstance } from '../axios.provider';
import { AdminsQueryParams } from './dto/admins.dto';
import { CreateAdmin } from './dto/create-admin.dto';
import { Admin, AdminListResponse } from './response/admin-list.response';

export async function apiGetAdminsList(params: AdminsQueryParams): Promise<AdminListResponse> {
  const response = await axiosInstance.get('/api/v1/admins', { params });
  return response.data;
}

export async function apiGetSingleAdmin(id: string): Promise<Admin> {
  const response = await axiosInstance.get(`/api/v1/admins/${id}`);
  return response.data;
}

export async function apiCreateAdmin(payload: CreateAdmin): Promise<ObjectIdResponse> {
  const response = await axiosInstance.post('/api/v1/admins', payload);
  return response.data;
}

export async function apiDeleteAdmin(id: string): Promise<ObjectIdResponse> {
  const response = await axiosInstance.delete(`/api/v1/admins/${id}`);
  return response.data;
}
