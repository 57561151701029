import { apiDeleteAdmin, apiGetAdminsList } from '@/api/admins/api';
import { AdminListResponse } from '@/api/admins/response/admin-list.response';
import { ObjectIdResponse } from '@/api/common/idResponse';
import { OperatorType } from '@/api/common/search.dto';
import { RootState } from '@/store';
import { toggleDirection } from '@/utils/common';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { actionCreateSnackbar } from '../notificationSlice/notificationSlice';
import { FilterColumns, SortColumns, SuperAdminInitialState } from './types';
import { FilterType } from '@/utils/types/Filters';

const initialState: SuperAdminInitialState = {
  data: {
    count: 0,
    list: [],
  },
  query: {
    page: 1,
    limit: 20,
    search: undefined,
    column: 'email',
    filterType: 'text',
    displayFilterName: 'Email',
    operator: 'co',
    sort: undefined,
    direction: undefined,
  },
  isLoading: false,
};

export const actionGetAdminList = createAsyncThunk<AdminListResponse, void, { state: RootState }>(
  'superAdmin/get-list',
  async (_, thunkAPI) => {
    const { page, limit, search, column, operator, sort, direction } =
      thunkAPI.getState().superAdmin.query;
    const offset = (page - 1) * limit;
    try {
      return await apiGetAdminsList({ offset, limit, search, column, operator, sort, direction });
    } catch (error) {
      thunkAPI.dispatch(
        actionCreateSnackbar({ message: error.response?.data?.message, variant: 'error' }),
      );
      thunkAPI.rejectWithValue(error);
    }
  },
);

export const actionAdminSetPage = createAsyncThunk<void, number, { state: RootState }>(
  'superAdmin/set-page',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAdminList());
  },
);

export const actionAdminSetLimit = createAsyncThunk<void, number, { state: RootState }>(
  'superAdmin/set-limit',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAdminList());
  },
);

export const actionAdminSetSearch = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState }
>('superAdmin/set-search', async (_, thunkAPI) => {
  thunkAPI.dispatch(actionGetAdminList());
});

export const actionAdminSetColumn = createAsyncThunk<
  void,
  { data: string; type: FilterType; displayFilterName: string },
  { state: RootState }
>('superAdmin/set-column', async (_, thunkAPI) => {
  if (thunkAPI.getState().superAdmin.query.search) {
    thunkAPI.dispatch(actionGetAdminList());
  }
});

export const actionAdminSetOperator = createAsyncThunk<void, string, { state: RootState }>(
  'superAdmin/set-operator',
  async (_, thunkAPI) => {
    if (thunkAPI.getState().superAdmin.query.search) {
      thunkAPI.dispatch(actionGetAdminList());
    }
  },
);

export const actionAdminSetSort = createAsyncThunk<void, string | undefined, { state: RootState }>(
  'superAdmin/set-sort',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(actionGetAdminList());
  },
);

export const actionDeleteAdmin = createAsyncThunk<ObjectIdResponse, string>(
  'superAdmin/delete-admin',
  async (id, thunkAPI) => {
    try {
      const response = await apiDeleteAdmin(id);

      await thunkAPI.dispatch(actionGetAdminList());
      thunkAPI.dispatch(
        actionCreateSnackbar({ message: t('modals:delete_admin.success'), variant: 'success' }),
      );
      return response;
    } catch (error) {
      console.log(error);
      thunkAPI.dispatch(
        actionCreateSnackbar({ message: error.response.data.message, variant: 'error' }),
      );
      thunkAPI.rejectWithValue(error);
    }
  },
);

export const superAdminSlice = createSlice({
  name: 'superAdminSlice',
  initialState,
  reducers: {
    actionClearSuperAdminStore: state => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionGetAdminList.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetAdminList.fulfilled, (state, action) => {
      state.data.count = action.payload.count;
      state.data.list = action.payload.rows;
      state.isLoading = false;
    });
    builder.addCase(actionGetAdminList.rejected, state => {
      state.isLoading = false;
    });
    /**
     * Query
     */
    builder.addCase(actionAdminSetPage.pending, (state, action) => {
      state.query.page = action.meta.arg;
    });
    builder.addCase(actionAdminSetLimit.pending, (state, action) => {
      state.query.limit = action.meta.arg;
      state.query.page = 1;
    });
    builder.addCase(actionAdminSetSearch.pending, (state, action) => {
      state.query.search = action.meta.arg || undefined; // to avoid empty string
      state.query.page = 1;
    });
    builder.addCase(actionAdminSetColumn.pending, (state, action) => {
      state.query.column = action.meta.arg.data as FilterColumns;
      state.query.filterType = action.meta.arg.type as FilterType;
      state.query.displayFilterName = action.meta.arg.displayFilterName as string;
      if (state.query.search) {
        state.query.page = 1;
      }
      if (action.meta.arg.type === 'text') {
        state.query.operator = 'co';
      }
      if (action.meta.arg.type === 'boolean') {
        state.query.operator = 'eq';
      }
      if (action.meta.arg.type === 'date') {
        state.query.operator = 'co';
      }
    });
    builder.addCase(actionAdminSetOperator.pending, (state, action) => {
      state.query.operator = action.meta.arg as OperatorType;
      if (state.query.search) {
        state.query.page = 1;
      }
    });
    builder.addCase(actionAdminSetSort.pending, (state, action) => {
      state.query.direction =
        state.query.sort === action.meta.arg ? toggleDirection(state.query.direction) : 'asc';
      state.query.sort = state.query.direction ? (action.meta.arg as SortColumns) : undefined;
    });
    builder.addCase(actionDeleteAdmin.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionDeleteAdmin.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(actionDeleteAdmin.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { actionClearSuperAdminStore } = superAdminSlice.actions;

export default superAdminSlice.reducer;
