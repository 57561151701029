import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

export const CustomMainButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#FFF',
  backgroundColor: '#102334',
  borderRadius: '4px',
  height: 40,
  boxSizing: 'border-box',
  fontSize: '14px',
  textTransform: 'none',
  padding: '10px 19px',
  fontFamily: ['-Open Sans', 'sans-serif'].join(','),
  fontWeight: 500,
  '&:hover': {
    backgroundColor: '#32383E',
  },
  '&:disabled': {
    backgroundColor: '#B5B5B5FF',
    color: '#fff',
    cursor: 'not-allowed',
  },
  '@media(min-width: 768px)': {
    fontSize: '16px',
  },
}));

export const CustomMainButtonOutline = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#101010',
  backgroundColor: 'transparent',
  fontFamily: ['-Open Sans', 'sans-serif'].join(','),
  textTransform: 'none',
  borderRadius: 'none',
  height: 40,
  boxSizing: 'border-box',
  fontSize: '14px',
  padding: '10px 20px',

  '@media(min-width: 768px)': {
    fontSize: '16px',
  },
}));

export const CustomTableDeleteButtonOutline = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#c12326',
  padding: '10px 0',
  fontFamily: ['-Open Sans', 'sans-serif'].join(','),
  textTransform: 'none',
  backgroundColor: 'transparent',
  borderRadius: 'none',
  height: 40,
  boxSizing: 'border-box',
  fontSize: '14px',
  '@media(min-width: 768px)': {
    fontSize: '16px',
  },
}));
