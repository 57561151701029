import { useAppSelector } from '@/customHooks/storeHooks';
import classNames from 'classnames/bind';
import React from 'react';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
// TODO change navigate
type MenuItemProps = {
  title: string;
  Icon: IconType;
  path?: string;
  navigate?: (path: string) => void;
};

const MenuItem: React.FC<MenuItemProps> = ({ title, Icon, navigate, path }) => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const iconClasses = cx({
    icon_active: location.pathname.startsWith(path),
    icon_inActive: !location.pathname.startsWith(path),
  });

  const titleClasses = cx({
    title_active: location.pathname.startsWith(path),
    title_inActive: !location.pathname.startsWith(path),
  });
  const isDrawerOpen = useAppSelector(state => state.theme.isDrawerOpen);
  return (
    <div onClick={() => navigate(path)} className={styles.item_container}>
      <div
        style={{
          justifyContent: 'center',
        }}
        className={styles.icon_container}
      >
        <Icon className={iconClasses} size={24} />
      </div>
      <span style={{ display: isDrawerOpen ? 'block' : 'none' }} className={titleClasses}>
        {title}
      </span>
    </div>
  );
};

export default React.memo(MenuItem);
