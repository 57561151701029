import { LoginResponse, RefreshResponse } from '@/api/auth/response/login.response';
import { Direction } from '@/api/common/search.dto';
import { LoginResponseDecode } from '@/store/slices/authSlice/types';
import jwt_decode from 'jwt-decode';

export const toggleDirection = (value: Direction | undefined): Direction | undefined => {
  if (value === undefined) {
    return 'asc';
  }
  if (value === 'asc') {
    return 'desc';
  }
  if (value === 'desc') {
    return undefined;
  }
};

export const decodeToken = (response: RefreshResponse | LoginResponse): LoginResponseDecode => {
  let decode = null;
  if (response.access_token) {
    decode = jwt_decode(response.access_token);
  }
  const responseData: LoginResponseDecode = {
    response: response,
    decode: decode,
  };

  return responseData;
};
