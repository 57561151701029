import { ObjectIdResponse } from '../common/idResponse';
import { axiosInstance } from '../axios.provider';
import { UserStatus } from './dto/singleUser.dto';
import { UsersQueryParams } from './dto/users.dto';
import { SingleUserResponse } from './response/singleUser-response';
import { UsersListResponse } from './response/users-list-response';

export async function apiGetAllUsersList(params: UsersQueryParams): Promise<UsersListResponse> {
  const response = await axiosInstance.get('/api/v1/users', { params });
  return response.data;
}

export async function apiChangeUserStatus({ id, status }: UserStatus): Promise<ObjectIdResponse> {
  const response = await axiosInstance.put(`/api/v1/users/${id}/status`, { status });
  return response.data;
}

export async function apiGetSingleUser(id: string): Promise<SingleUserResponse> {
  const response = await axiosInstance.get(`/api/v1/users/${id}`, {});
  return response.data;
}

export async function apiAddUserDevice({ user_id, imei }: { user_id: string; imei: string }) {
  const response = await axiosInstance.post(`/api/v1/users/${user_id}/device`, { imei });
  return response.data;
}
